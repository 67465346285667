import PropTypes from 'prop-types';
import { Game } from 'CasinoGlobal/components';
import { useGames } from '../../section_components/hooks';
import { extractGameInstanceData } from '../helpers';

const GridComponent = ({ games, parentSessionId, tracking }) => {
  const gamesIds = useGames(games);
  const getGameInstance = (gameId) => (
    extractGameInstanceData(games.find((game) => game.id === gameId))
  );

  if (!gamesIds) return null;

  return (
    <ul className="casino-grid-component">
      {gamesIds.map((gameId) => (
        <Game
          key={gameId}
          gameId={gameId}
          gameInstance={getGameInstance(gameId)}
          callParent="mini-games"
          parentSessionId={parentSessionId}
          tracking={tracking}
          showFavorite={false}
        />
      ))}
    </ul>
  );
};

GridComponent.propTypes = {
  games: PropTypes.instanceOf(Array).isRequired,
  parentSessionId: PropTypes.string.isRequired,
  tracking: PropTypes.instanceOf(Object).isRequired,
};

export default GridComponent;
