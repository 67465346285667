import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { partial } from 'underscore';
import normalise from 'casino-normalisers/games';
import { addGames, removeGames } from 'casino-action-creators/games';
import { addStudios, removeStudios } from 'casino-action-creators/studios';

export default (games) => {
  const dispatch = useDispatch();

  const { entities: { games: normalisedGames, studios }, result: gamesIds } = normalise(games);

  useEffect(() => {
    dispatch(addStudios(studios));
    dispatch(addGames(normalisedGames));

    return () => {
      dispatch(removeStudios(Object.keys(studios).map(partial(parseInt, _, 10))));
      dispatch(removeGames(gamesIds));
    };
  }, gamesIds);

  // We have to wait until games are added to the state
  // Otherwise the list will be first show empty and the carousel won't show arrows
  const gamesInState = useSelector((state) => (
    gamesIds.every((gameId) => state['casino/games'][gameId])
  ));

  if (!gamesInState) return null;

  return gamesIds;
};
