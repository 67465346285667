import { useState, useEffect } from 'react';
import { debounce, compose } from 'underscore';
import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { useParams, useLocation } from 'react-router';

import * as Components from './components';
import { fetchComponents } from './api';

const isVisible = () => window.innerHeight > 0;

const App = () => {
  const { slug } = useParams();
  const [visible, setVisible] = useState(isVisible());
  const [fetched, loading] = useFetch(
    () => fetchComponents(slug),
    [slug],
  );

  const queryParams = new URLSearchParams(useLocation().search);
  const parentSessionId = queryParams.get('game_session_id');

  useEffect(() => {
    const handleResize = debounce(compose(setVisible, isVisible), 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) return <Spinner />;
  if (!visible || !fetched.components) return null;

  return (
    <div className="casino-mini-games has-scroll-bars">
      { fetched.components.map(({ type, name, games }) => {
        const Component = Components[type];

        const tracking = { path: 'mini_games_components', type };

        return Component ? (
          <Component
            key={name}
            games={games}
            parentSessionId={parentSessionId}
            tracking={tracking}
          />
        )
          : null;
      })}
    </div>
  );
};

export default App;
